<template>
  <b-card>
    <validation-observer ref="userForm" tag="form">
      <!-- <b-form @submit.prevent> -->
      <b-row v-if="$can('create', 'user')">
        <b-col cols="12">
          <b-form-group>
            <b-media no-body>
              <b-media-aside>
                <b-link>
                  <b-avatar
                    ref="previewEl"
                    :src="
                      form.profile_image
                        ? $FILES_URL + form.profile_image
                        : null
                    "
                    size="90px"
                    rounded
                  />
                </b-link>
              </b-media-aside>

              <b-media-body class="mt-75 ml-75">
                <!-- upload button -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="$refs.refUserProfileInputEl.$el.click()"
                >
                  Upload
                </b-button>
                <b-form-file
                  ref="refUserProfileInputEl"
                  accept=".jpg, .png, .gif"
                  :hidden="true"
                  plain
                  @change="uploadProfileImage"
                />
                <b-card-text
                  >Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text
                >
              </b-media-body>
            </b-media>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6">
          <b-form-group label="Name" label-for="mc-name">
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-input
                id="mc-name"
                placeholder="Name"
                v-model="form.name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12" sm="12" md="6" lg="6">
          <b-form-group label="Email" label-for="mc-email">
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-input
                id="mc-email"
                placeholder="Email"
                v-model="form.email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6">
          <b-form-group label="Mobile Number" label-for="mc-mobile">
            <VuePhoneNumberInput v-model="form.mobile" />
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6">
          <b-form-group label="Role" label-for="mc-role">
            <validation-provider
              #default="{ errors }"
              name="Role"
              rules="required"
            >
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="role_name"
                :options="allRoles"
                :reduce="(role) => role.role_id"
                v-model="form.role"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6">
          <b-form-group label="Password" label-for="mc-passd">
            <b-form-input
              id="mc-passd"
              placeholder="Password"
              v-model="form.password"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6">
          <b-form-group label="Country" label-for="mc-country">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="lbl_name"
              :options="$store.state.master.country"
              :reduce="(label) => label.lbl_id"
              v-model="form.country"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="User Status">
            <b-form-checkbox
              v-model="form.status"
              name="check-button"
              switch
              inline
            />
          </b-form-group>
        </b-col>
        <!-- submit and reset -->

        <b-col cols="12" lg="12" md="12">
          <b-button
            :disabled="!$can('create', 'user')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            @click="saveUser"
          >
            Submit
          </b-button>
        </b-col>
      </b-row>
      <!-- </b-form> -->
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormDatepicker,
  BInputGroupAppend,
  BInputGroup,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormFile,
  BLink,
  BAvatar,
  BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import VuePhoneNumberInput, { async } from "vue-phone-number-input";
import * as UserServices from "@/apiServices/UsersServices";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { labels } from "@/utils/constants";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import { uploadImages } from "@/utils/helpers";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormDatepicker,
    BInputGroupAppend,
    BInputGroup,
    VuePhoneNumberInput,
    ValidationProvider,
    ValidationObserver,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormFile,
    BLink,
    BAvatar,
    BCardText,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        profile_image: "",
        user_id: null,
        name: null,
        email: null,
        mobile: null,
        role: null,
        country: null,
        password: null,
        status: true,
        // company: null,
      },
      allRoles: [],
      required,
      email,
    };
  },
  methods: {
    async uploadProfileImage(e) {
      const file = e.target.files[0];

      if (file.size > 800 * 1024) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "File size cannot be greater than 800kbs",
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }

      const fileUrl = await uploadImages(e);
      if (fileUrl && fileUrl.length) {
        this.form.profile_image = fileUrl[0];
      }
    },
    async getUserDetails(user_id) {
      try {
        const response = await UserServices.getSingleUser({
          user_id,
        });

        if (response.data.status) {
          this.form = {
            ...this.form,
            ...response.data.data,
            status: response.data.data.status ? true : false,
          };
        }
      } catch (error) {
        console.log("Error in getUserDetails ", error);
      }
    },
    async getUserRoles() {
      try {
        const response = await UserServices.getAllRoles();
        if (response.data.status) {
          this.allRoles = response.data.data.data;
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },
    async saveUser() {
      try {
        this.$refs.userForm.validate().then(async (success) => {
          if (!success) return;

          const response = await UserServices.saveUser(this.form);
          if (response.data.status) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: response.data.message || "Success",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.$router.go(-1);
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: response.data.message || "Not Saved",
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        });
      } catch (err) {
        console.log("Error in saving user details  ", err);
      }
    },
  },
  beforeMount() {
    const user_id = this.$route.params.user_id;

    if (user_id && !isNaN(user_id)) {
      this.form.user_id = user_id;
      this.getUserDetails(user_id);
    }

    this.$store.dispatch("master/setLabelMaster", { key: labels.country });
    this.getUserRoles();
  },
};
</script>
<style scoped></style>
